import gsap from 'gsap';
import Swiper from 'swiper/bundle';

export default class CardRewards {
	animate() {
		const steps = document.querySelectorAll('.card-rewards__levels-step');
		
		gsap.from(steps, {
			x: -20,
			opacity: 0,
			duration: 1,
			stagger: 0.1
		});
		
		const bonuses = document.querySelectorAll('.card-rewards__bonuses li');
		
		gsap.from(bonuses, {
			y: 20,
			opacity: 0,
			duration: 1,
			stagger: 0.1
		});
	}
	
	getActivity() {
		return document.querySelector('.card-rewards__activity');
	}
	
	showActivity() {
		const activity = this.getActivity();
		activity.style.display = 'block';
		
		const rows = document.querySelectorAll('.card-rewards__activity td');

		gsap.from(rows, {
			y: 20,
			opacity: 0,
			duration: 1,
			stagger: 0.1
		});
	}
	
	hideActivity() {
		const activity = this.getActivity();
		activity.style.display = 'none';
	}
	constructor() {
		this.swiper = null;
		
		document.addEventListener('components_init', () => {
			this.init();
		});
	}

	init() {
		const swiper = document.querySelector('.card-deals__swiper.rewards-swiper');
		
		if (!swiper) {
			return;
		}
		
		const pagination = swiper.querySelector('.card-deals__pagination.rewards-pagination');
		
		this.swiper = new Swiper(swiper, {
			loop: true,
			pagination: {
				el: pagination,
                clickable: true
			}
		});

	}
}