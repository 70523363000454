export default class Button {

	constructor() {
		document.addEventListener('components_init', () => {
			this.init();
		});
	}

	init() {
		function isTabbing(e) {
			if (e.keyCode === 9) {
				document.body.classList.add('enable-focus-outlines');
				window.removeEventListener('keydown', isTabbing);
			}
		}

		window.addEventListener('keydown', isTabbing);
	}

}
