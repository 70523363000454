import gsap from 'gsap';

export default class CardsGrid {

	animate() {
		const cards = document.querySelectorAll('.cards-grid__card');
		
		gsap.from(cards, {
			scale: 0,
			duration: 0.75,
			stagger: 0.1
		});
	}

}