import gsap from 'gsap';
import Swiper from 'swiper/bundle';

export default class CardDeals {

	constructor() {
		this.swiper = null;
		
		document.addEventListener('components_init', () => {
			this.init();
		});
	}

	init() {
		const swiper = document.querySelector('.card-deals__swiper.deals-only');
		
		if (!swiper) {
			return;
		}
		
		const pagination = swiper.querySelector('.card-deals__pagination.deals-only');
		
		this.swiper = new Swiper(swiper, {
			loop: true,
			pagination: {
				el: pagination,
				clickable: true
			}
		});

	}
	
	animate() {
		const cells = document.querySelectorAll('.card-deals__grid div');
		
		gsap.from(cells, {
			transform: 'translateY(60px)',
			opacity: 0,
			duration: 1,
			stagger: 0.1
		});
	}
	
}