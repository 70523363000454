import Swiper from 'swiper/bundle';

export default class CardVouchers {

	constructor() {
		this.swiper = null;
		
		document.addEventListener('components_init', () => {
			this.init();
		});
	}

	init() {
		const swiper = document.querySelector('.card-deals__swiper.vouchers-swiper');
		
		if (!swiper) {
			return;
		}
		
		const pagination = swiper.querySelector('.card-deals__pagination.vouchers-pagination');
		
		this.swiper = new Swiper(swiper, {
			loop: true,
			pagination: {
				el: pagination,
                clickable: true
			}
		});
	}
}