import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

export default class UserProfile {

	constructor() {
		this.datepicker = null;
		this.cropper = null;

		document.addEventListener('components_init', () => {
			this.init();
		});
	}

	getElements() {
		this.elements = {
			birthday: document.querySelector('#user-profile__form-birthday'),
			modal: document.querySelector('#user-profile__avatar-modal'),
			panel: document.querySelector('#user-profile__avatar-modal-panel'),
			show: document.querySelector('#user-profile__avatar-show-modal'),
			upload: document.querySelector('#user-profile__avatar-upload'),
			zoom: document.querySelector('#user-profile__avatar-zoom'),
			rotate: document.querySelector('#user-profile__avatar-rotate'),
			save: document.querySelector('#user-profile__avatar-save'),
			cancel: document.querySelector('#user-profile__avatar-cancel'),
			file: document.querySelector('#user-profile__avatar-file'),
			image: document.querySelector('#user-profile__avatar-cropper-img'),
			info: document.querySelector('#popup-message')
		}
	}

	init() {
		this.getElements();

		if (this.elements.birthday) {
			this.initBirthday();
		}

		if (this.elements.modal) {
			this.initAvatar();
		}

		const addressField = document.getElementsByName('id-address-country');
		if(addressField.length > 0) {
			this.listenSelect(addressField[0]);
		}

		const locationField = document.getElementsByName('id-preferred-location');
		if(locationField.length > 0) {
			this.listenSelect(locationField[0]);
		}

		// const languageField = document.getElementsByName('id-language');
		// if(languageField.length > 0) {
		// 	this.listenSelect(languageField[0]);
		// }

		const titleField = document.getElementsByName('id-title');
		if(titleField.length > 0) {
			this.listenSelect(titleField[0]);
		}

		const emailField = document.getElementsByName('id-emaillist');
		if(emailField.length > 0) {
			this.listenSelect(emailField[0]);
		}
		const phoneField = document.getElementsByName('id-phonelist');
		if(phoneField.length > 0) {
			this.listenSelect(phoneField[0]);
		}
	}

	initAvatar() {
		this.elements.save.onclick = () => {
			this.hideAvatarModal();
		}

		this.elements.cancel.onclick = () => {
			this.hideAvatarModal();
		}

		this.elements.upload.onclick = () => {
			this.elements.file.click();
		}

		this.elements.zoom.oninput = () => {
			this.cropper.zoomTo(this.elements.zoom.value);
		}

		this.elements.rotate.onclick = () => {
			this.cropper.rotate(90);
		}

		this.elements.file.onchange = () => {
			this.loadImage();
		}

		this.cropper = new Cropper(this.elements.image, {
			viewMode: 3,
			dragMode: 'move',
			autoCropArea: 1,
			restore: false,
			modal: false,
			guides: false,
			highlight: false,
			background: false,
			cropBoxMovable: false,
			cropBoxResizable: false,
			toggleDragModeOnDblclick: false,
			center: false,
			initialAspectRatio: 1,
			aspectRatio: 1
		});
	}

	loadImage() {
		const file = this.elements.file;

		if (file.files && file.files[0]) {
			var reader = new FileReader();

			reader.onload = (e) => {
				this.cropper.replace(e.target.result);
				this.elements.zoom.value = 0;
			}

			reader.readAsDataURL(file.files[0]);
		}
	}

	getImageData() {
		const canvas = this.cropper.getCroppedCanvas({
			width: 1280,
			height: 1280,
			minWidth: 1280,
			minHeight: 1280,
			maxWidth: 1280,
			maxHeight: 1280,
			fillColor: '#ffffff',
			imageSmoothingEnabled: true,
			imageSmoothingQuality: 'high'
		});

		return canvas.toDataURL('image/jpeg', 0.8);
	}

	showAvatarModal() {

		this.elements.modal.style.display = 'flex';
		if (this.elements.modal !== undefined) {
			this.cropper.resize();
		}
	}

	hideAvatarModal() {
		this.elements.modal.style.display = 'none';
	}
	showResponse(outcome) {
		const responseDiv = document.querySelector(".user-profile__response");
		if (outcome === "yes") {
			responseDiv.classList.add("user-profile__response--success")
			responseDiv.innerHTML = "Profile Saved!";
		}else {
			responseDiv.classList.add("user-profile__response--failure")
			responseDiv.innerHTML = "Sorry, there was a problem saving your profile.";
		}
	}
	resetUserProfileDropDownIndex(name) {
		document.getElementsByName(name).forEach(function(e) { 
			e.selectedIndex = 0;
		});
	}

	clearValidationMessages() {
		this.trySetInnerHtmlById('id-title', "" );
		this.trySetInnerHtmlById('id-givenname', "" );
		this.trySetInnerHtmlById('id-surname', "" );
		this.trySetInnerHtmlById('id-nickname', "" );
		this.trySetInnerHtmlById('id-newemail', "" );
		this.trySetInnerHtmlById('id-emaillist', "" );
		this.trySetInnerHtmlById('id-newphone', "" );
		this.trySetInnerHtmlById('id-phonelist', "" );
		this.trySetInnerHtmlById('id-dateofbirth', "" );
		this.trySetInnerHtmlById('id-dateofbirth-day', "" );
		this.trySetInnerHtmlById('id-dateofbirth-month', "" );
		this.trySetInnerHtmlById('id-dateofbirth-year', "" );
		this.trySetInnerHtmlById('id-address', "" );
		this.trySetInnerHtmlById('id-address-street', "" );
		this.trySetInnerHtmlById('id-address-suburb', "" );
		this.trySetInnerHtmlById('id-address-state', "" );
		this.trySetInnerHtmlById('id-address-postcode', "" );
		this.trySetInnerHtmlById('id-address-city', "" );
		this.trySetInnerHtmlById('id-address-country', "" );
		this.trySetInnerHtmlById('id-preferred-location', "")
		// this.trySetInnerHtmlById('id-language', "" );

		this.tryRemoveClassByName('id-title', "hasError");
		this.tryRemoveClassByName('id-givenname', "hasError");
		this.tryRemoveClassByName('id-surname', "hasError");
		this.tryRemoveClassByName('id-nickname', "hasError");
		this.tryRemoveClassByName('id-dateofbirth-day', "hasError");
		this.tryRemoveClassByName('id-dateofbirth-month', "hasError");
		this.tryRemoveClassByName('id-dateofbirth-year', "hasError");
		this.tryRemoveClassByName('id-address-street', "hasError");
		this.tryRemoveClassByName('id-address-suburb', "hasError");
		this.tryRemoveClassByName('id-address-state', "hasError");
		this.tryRemoveClassByName('id-address-postcode', "hasError");
		this.tryRemoveClassByName('id-address-city', "hasError");
		this.tryRemoveClassByName('id-address-country', "hasError");
		this.tryRemoveClassByName('id-preferred-location', "hasError");
		// this.tryRemoveClassByName('id-language', "hasError");

		var newEmail = document.getElementsByName('id-newemail')[0];
		if(newEmail) {
			newEmail.classList.remove("hasError");
		}

		var emailList = document.getElementsByName('id-emaillist')[0];
		if(emailList) {
			emailList.classList.remove("hasError");
		}

		var newPhone = document.getElementsByName('id-newphone')[0];
		if(newPhone) {
			newPhone.classList.remove("hasError");
		}

		var phoneList = document.getElementsByName('id-phonelist')[0];
		if(phoneList) {
			phoneList.classList.remove("hasError");
		}
	}

	trySetInnerHtmlById(id, newHtml) {
		var e = document.getElementById(id);
		if (!!e) {
			e.innerHTML = newHtml;
		}
	}

	tryRemoveClassByName(name, classToRemove) {
		var e = document.getElementsByName(name);
		if (!!e && e.length > 0) {
			e[0].classList.remove(classToRemove);
		}
	}

	addValidationMessage(id, message) {
		document.getElementById(`${id}`).innerHTML +=
		`<label>${message}</label>`;
		var elem = 	document.getElementsByName(`${id}`)[0];
		if(elem) {
			elem.classList.add('hasError');
		}
	}

	listenSelect(elem) {
		if (elem.value==""){
			elem.style.color="#BFC5CB";

		}
		if (elem != null) {
			elem.addEventListener('change', (e) => {
				elem.style.color="black";
			  });
			}
	}

	addVietnamese(lang) {
		var bodyClass = document.getElementsByTagName('body')[0];

		if (lang === "vi") {
			bodyClass.classList.add("vi");
		} else {
			bodyClass.classList.remove("vi");
		}
	}
}
