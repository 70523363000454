import gsap from 'gsap';

export default class CardsToolbar {

	getBlind() {
		return document.querySelector('.cards-toolbar__blind-panel');
	}
	
	getTint() {
		return document.querySelector('.cards-toolbar__tint');
	}
	
	getHeading() {
		return document.querySelectorAll('.cards-toolbar__heading--menu');
	}

	showMenu() {
		const blind = this.getBlind();
		const tint = this.getTint();
		const heading = this.getHeading();
		
		blind.style.display = 'block';
		
		gsap.to(blind, {
			height: 'auto',
			duration: 0.5
		});
		
		gsap.to(tint, {
			opacity: 0.5,
			display: 'block',
			duration: 0.5
		});
		
		heading.forEach((element) => {
			element.classList.add('cards-toolbar__heading--menu--open');
		});
		
		if (tint != null) {
        tint.addEventListener('click', function (event) {
			gsap.to(blind, {
				height: 0,
				display: 'none',
				duration: 0.5
			});
			
			gsap.to(tint, {
				opacity: 0,
				display: 'none',
				duration: 0.5
			});
			heading.forEach((element) => {
				element.classList.remove('cards-toolbar__heading--menu--open');
			});
			
        });
	}
	}
	
	hideMenu() {
		const blind = this.getBlind();
		const tint = this.getTint();
		const heading = this.getHeading();

		gsap.to(blind, {
			height: 0,
			display: 'none',
			duration: 0.5
		});
		
		gsap.to(tint, {
			opacity: 0,
			display: 'none',
			duration: 0.5
		});
		
		heading.forEach((element) => {
			element.classList.remove('cards-toolbar__heading--menu--open');
		});
		
	}
	
}