export default class PortalNav {
  constructor() {
    document.addEventListener("components_init", () => {
      this.activeTab();
    });
  }

  activeTab() {
    let activeTab;
    let tabOptions = document.querySelectorAll(".portal-nav__item");
    tabOptions.forEach((option) => {
      option.addEventListener("click", function () {
        if (activeTab) activeTab.classList.remove("portal-nav__item--selected");
        activeTab = option;
        activeTab.classList.add("portal-nav__item--selected");
      });
    });
  }
}
