import Button from './Components/button.js';

import MainNav from './Components/main-nav.js';
import PortalModal from './Components/portal-modal.js';
import PortalNav from './Components/portal-nav.js'
import CardsGrid from './Components/cards-grid.js';
import CardsList from './Components/cards-list.js';
import CardsToolbar from './Components/cards-toolbar.js';

import CardOverview from './Components/card-overview.js';
import CardDeals from './Components/card-deals.js';
import CardRewards from './Components/card-rewards.js';
import CardVouchers from './Components/cards-vouchers.js';

import UserProfile from './Components/user-profile.js';

import LoadingAnimation from './Components/loading-animation.js';

import PopupMessage from './Components/popup-message.js';
import FileDownload from './Components/file-download.js';

import Verify from './Components/verify.js';
import NotificationAlert from './Components/notification-alert.js';




window.components = {};

const init = {
	Button,
	PortalModal,
	MainNav,
	CardsGrid,
	CardsList,
	CardsToolbar,
	CardOverview,
	CardDeals,
	CardRewards,
	UserProfile,
	PortalNav,
	LoadingAnimation,
	PopupMessage,
	FileDownload,
	Verify,
	CardVouchers,
	NotificationAlert
};

for(let i in init) {
	const component = new init[i]();
	window.components[i] = component;
}

document.dispatchEvent(new Event('components_init'));