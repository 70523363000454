import gsap from "gsap";

export default class NotificationAlert {
  displayAlert() {
    const notification = document.querySelector(".notification-alert");

    if (!notification) {
      return;
    }

    gsap.fromTo(
      notification,
      {
        opacity: 0,
        display: "none",
        duration: 0.5,
      },
      {
        opacity: 1,
        display: "flex",
        duration: 0.5,
      }
    );

    setTimeout(function () {
      gsap.fromTo(
        notification,
        {
          opacity: 1,
          display: "flex",
          duration: 0.7,
        },
        {
          opacity: 0,
          display: "none",
          duration: 0.7,
        }
      );
    }, 3000);
  }
}
