import gsap from "gsap";
import { CountUp } from "countup.js";

export default class CardOverview {
  animate() {
    const bar = document.querySelector(".card-overview__bar");
    const inner = bar.querySelector("div");
    const start = document.querySelector(".card-overview__meter-mark--start");
    const middle = document.querySelector(".card-overview__meter-mark--middle");
    const end = document.querySelector(".card-overview__meter-mark--end");
    const counts = document.querySelectorAll(
      ".card-overview__credit-count[data-count]"
    );
    const etickets = document.querySelector(".etickets");
    const gamecredits = document.querySelector(".game-credits");
    console.log(bar.dataset.value);
    gsap.to(inner, {
      width: `${bar.dataset.value}%`,
      duration: 1,
    });

    gsap.to(start, {
      transform: "rotateY(180deg)",
      opacity: 1,
      duration: 1,
    });

    gsap.to(middle, {
      left: `${Math.min(bar.dataset.value, "88")}%`,
      opacity: 1,
      duration: 1,
    });

    gsap.to(end, {
      transform: "scale(1)",
      duration: 1,
    });

    counts.forEach((element) => {
      element.style.visibility = "visible";
      countComplete();
      var countUp = new CountUp(element, element.dataset.count, {
        duration: 1,
        decimalPlaces: element.dataset.countDecimal,
        separator: element.dataset.countSeparator,
        prefix: element.dataset.countPrefix,
      });
      countUp.start();
    });

    function countComplete() {
      var gameCreditsLength =
        gamecredits !== null && gamecredits.innerHTML !== null
          ? gamecredits.innerHTML.length
          : 0;
      var eticketsLength =
        etickets !== null && etickets.innerHTML !== null
          ? etickets.innerHTML.length
          : 0;
      var maxValue = Math.max(eticketsLength, gameCreditsLength);

      if (maxValue < 7) {
        etickets.style.fontSize = "5rem";
        gamecredits.style.fontSize = "5rem";
      } else {
        etickets.style.fontSize = "3rem";
        gamecredits.style.fontSize = "3rem";
      }
    }

    setTimeout(() => {
      const middleLeft = middle.style.left;

      if (middleLeft === "100%") {
        middle.style.display = "none";
      }
    }, 1000);
  }
}
