export default class Verify {

  // TCP-799 - Stashing for now.
  // There's an issue with getting the binds between Javascript and Blazor to cooperate successfully to paste the code into
  // the verification modal. We should take a look at this another time.
  //

  // constructor() {
  //   this.DotNetReference = null;
  // }

  // setDotnetReference(pDotNetReference) {
  //     if (this.DotNetReference === null)
  //     {
  //       this.DotNetReference = pDotNetReference;
  //     }
  // };

  // addPasteListener() {
  //   const otpInputs = document.getElementsByClassName('verification-input__input');
  //   [...otpInputs].forEach(input => {
  //     input.addEventListener('paste', this.handlePaste);
  //   });
  // }

  // handlePaste(e) {
  //   var clipboardData, pastedData;

  //   console.log('handle paste');
  //   //Stop data actually being pasted into input
  //   e.stopPropagation();
  //   e.preventDefault();

  //   clipboardData = e.clipboardData || window.clipboardData;
  //   pastedData = clipboardData.getData('Text');
  //   console.log(pastedData);

  //   // if(pastedData.length === 6) {
  //   //   for (let i=0; i < pastedData.length; i++) {
  //   //     console.log(pastedData.charAt(i));
  //   //     document.getElementById(`input-${i}`).value = pastedData.charAt(i);
  //   //   }

  //   //   const btnElem = document.getElementById('verification-modal-button');
  //   //   btnElem.classList.remove('generic-modal__button--disabled');
  //   // }
  //   // this.DotNetReference.invokeMethodAsync('TEEG.CustomerPortal.WebClient', 'PopulateOTPArray', pastedData);
  // }

  error() {
    const codeUpdate = document.querySelector(
      ".portal-modal__verification .verification-input__wrapper"
    );
    codeUpdate.classList.add("verification-input__wrapper--error");
    setTimeout(function () {
      codeUpdate.classList.remove("verification-input__wrapper--error");
    }, 1000);
  }

  checkCodeLength() {
    const confirmBtn = document.querySelector(
      ".verification-input__button .generic-modal__button--disabled"
    );
    if (!!confirmBtn) {
      confirmBtn.classList.remove("generic-modal__button--disabled");
      confirmBtn.classList.add("generic-modal__button--blue");
    }
  }

  addDisabledState() {
    const btnBlue = document.querySelector(
      ".verification-input__button .generic-modal__button--blue"
    );
    if (!!btnBlue) {
      btnBlue.classList.remove("generic-modal__button--blue");
      btnBlue.classList.add("generic-modal__button--disabled");
    }
  }
}
